function communicationPlatform() {
  var connection = null;

  /**
   * Request new token
   * @return void
   */
  function init(token, uid, appCode, email, cb) {
    //var email = "romerjeancarlos@gmail.com";
    console.log("Hola como estas?");
    var URL =
      "https://comm-platform.worldwidetechconnections.com/ConnectionHub?token=" +
      token +
      "&uid=" +
      uid +
      "&appcode=" +
      appCode +
      "&email=" +
      email;
    connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 60000) {
            // If we've been reconnecting for less than 60 seconds so far, wait for 2 seconds before reconnecting.
            return 2000;
          } else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
          }
        },
      })
      .build();

    connection
      .start()
      .then((res) => console.log(res))
      .catch((err) => cb(false));

    connection.on("RequestAccessResponse", (response) => {
      if (response.IsSuccess) {
        connection.on("DisconnectAccess", (response) => {
          disconnectAccess();
          console.log("disconnect");
          window.close();
        });
        return cb({
          ok: true,
          response,
        });
      } else {
        // disconnectAccess();
        cb({
          ok: false,
          response,
        });
      }
    });
  }
  // Disconnects the websocket connection
  function disconnectAccess() {
    connection.stop();
    // TODO: UI Developer - Write Code To Logout User from the app below
  }

  // Fetches the remaining time balance
  function getTimeBalance(token, callback) {
    connection.invoke("GetTimeBalanceByToken", token);

    connection.on("SetTimeBalance", (response) => {
      // Current balance
      callback(response);
      console.log(response);
    });
  }

  return {
    init: init,
    disconnect: disconnectAccess,
    getTime: getTimeBalance,
    connection: connection,
  };
}
